import {useState} from "react";

import './QuestionBox.scss';
import ReactGA from "react-ga";

type QuestionInfo = {
    "question": string,
    "choices": { "text": string, "points": string[] }[]
    "index": number,
    "setSelections": Function,
    "questionIndex": number,
    "questionsCount": number
}

export const QuestionBox = (props: QuestionInfo) => {

   document.addEventListener("touchstart", function(){}, true);

    const [clickedIndex, setClickedIndex] = useState(-1)
    const convertNumberToStringForm = (index: number) => {
        switch (index) {
            case 1:
                return "One"
            case 2:
                return "Two"
            case 3:
                return "Three"
            case 4:
                return "Four"
            case 5:
                return "Five"
            case 6:
                return "Six"
            case 7:
                return "Seven"
            case 8:
                return "Eight"
            case 9:
                return "Nine"
            default:
                return "Too many questions"
        }
    }

    const calcPosition = (e: any)=>{

        if(window.getComputedStyle(document.documentElement).getPropertyValue("--content") != '"'){
            document.documentElement.style.setProperty("--content", '""')
        }

        let xOffset = e.target.getBoundingClientRect().x
        let yOffset  = e.target.getBoundingClientRect().y

        let xFinal = e.clientX - xOffset
        let yFinal = e.clientY - yOffset

        document.documentElement.style.setProperty("--x-coord", `${xFinal}px`)
        document.documentElement.style.setProperty("--y-coord", `${yFinal}px`)
    }

    return (
        <div className={"question-box"}>
            <header>
                <label htmlFor={'question-'+props.questionIndex}>Question {convertNumberToStringForm(props.questionIndex + 1)}</label>
                <ul>
                    {[...Array(props.questionsCount)].map((c, index)=><li key={'dot-'+index} className={index === props.questionIndex ? 'current' : ''}></li>)}
                </ul>
            </header>
            <div className="question-wrapper" id={'question-'+props.questionIndex}>
                <h2>
                    {props.question}
                </h2>
                <ul className="choices">

                    {props.choices.map((choice, index) => {

                        return <li key={choice.text}>
                            <label>
                                <input required type="radio" name={props.question}
                                       onChange={() => {
                                           ReactGA.event({
                                               category: 'Question',
                                               action: props.question,
                                               label: choice.text
                                           });
                                    props.setSelections(props.index, choice.points);
                                    setClickedIndex(index)
                                }}/>
                                <span className={'card'}
                                      onAnimationEnd={()=>{
                                          if(window.getComputedStyle(document.documentElement).getPropertyValue("--content") != "none"){
                                              document.documentElement.style.setProperty("--content", "none")
                                          }
                                      }}
                                      onClick={(e)=>{
                                          calcPosition(e)
                                      }}><span>{choice.text}</span></span>
                            </label>
                        </li>
                    })}
                </ul>
            </div>
        </div>
    )
}
