import {useParams} from "react-router-dom";

import results from '../results';

import './ResultsPage.scss';

import iconArchitect from '../assets/images/personalities/architect.svg';
import iconBard from '../assets/images/personalities/bard.svg';
import iconCrafter from '../assets/images/personalities/crafter.svg';
import iconHarmonizer from '../assets/images/personalities/harmonizer.svg';
import iconObserver from '../assets/images/personalities/observer.svg';
import iconScribe from '../assets/images/personalities/scribe.svg';
import iconStrategizer from '../assets/images/personalities/strategizer.svg';
import iconVisionary from '../assets/images/personalities/visionary.svg';

import headshotArchitect from '../assets/images/headshots/architect.webp';
import headshotBard from '../assets/images/headshots/bard.webp';
import headshotCrafter from '../assets/images/headshots/crafter.webp';
import headshotHarmonizer from '../assets/images/headshots/harmonizer.webp';
import headshotObserver from '../assets/images/headshots/observer.webp';
import headshotScribe from '../assets/images/headshots/scribe.webp';
import headshotStrategizer from '../assets/images/headshots/strategizer.webp';
import headshotVisionary from '../assets/images/headshots/visionary.webp';
import {FormEvent, useEffect, useState} from "react";

// @ts-ignore
import placeholder from '../assets/placeholder.mp4'
import {ResultBox} from "../components/ResultBox/ResultBox";
import ReactGA from "react-ga";

export const ResultsPage = () => {

    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [submissionStored, setSubmissionStored] = useState(false);
    const [reattemptCount, setReattemptCount] = useState(0);

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    useEffect( () =>{
        window.scroll(0,0)
    },[])

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    const {personality} = useParams();
    const personalities = personality!.split("-")

    const findHeadshot = (personality: string) =>{
        switch (personality){
            case "scribe":
                return headshotScribe
            case "architect":
                return headshotArchitect
            case "bard":
                return headshotBard
            case "visionary":
                return headshotVisionary
            case "observer":
                return headshotObserver
            case "harmonizer":
                return headshotHarmonizer
            case "crafter":
                return headshotCrafter
            case "strategizer":
                return headshotStrategizer
            default:
                return headshotArchitect
        }
    }

    const findIcon = (personality: string) =>{
        switch (personality){
            case "scribe":
                return iconScribe
            case "architect":
                return iconArchitect
            case "bard":
                return iconBard
            case "visionary":
                return iconVisionary
            case "observer":
                return iconObserver
            case "harmonizer":
                return iconHarmonizer
            case "crafter":
                return iconCrafter
            case "strategizer":
                return iconStrategizer
            default:
                return iconVisionary
        }
    }

    const submit = (e:FormEvent) => {
        e.preventDefault();
        const submissionResult = storeSubmission();

        if( submissionResult ){
            setSubmitted(true);

            ReactGA.event({
                category: 'Form',
                action: 'Submit',
                label: personality
            });
        }
    };

    const storeSubmission = () => {
        if( !personality ){
            alert('Something went wrong. Please refresh the page and try again.');
            return false;
        }
        if( !email ){
            alert( 'Please fill in your email to see your results');
            return false;
        }

        const API_URL = 'https://gud1zodamb.execute-api.us-east-2.amazonaws.com/v1/submissions';
        const API_KEY = 'SLE[b43&X}\'inzYjbdh`(l%Qn^bPx,*x';
        const options = {
            method: 'POST',
            headers: {
                'x-api-key': API_KEY
            },
            body: JSON.stringify({
                first:firstName ? firstName : null,
                last:lastName ? lastName : null,
                email:email,
                type:personality,
            })
        };
        fetch(API_URL, options).then(response => response.json()).then(data => {
            setSubmissionStored(true);
        }).catch(error => {
            setReattemptCount(reattemptCount => reattemptCount+1);
        });
        return true;
    };

    useEffect(()=>{
        if( !email || reattemptCount === 0 || submissionStored ){
            return;
        }
        //if submission fails, wait 10 seconds and reattempt
        let timer:ReturnType<typeof setTimeout>;
        if( !submissionStored && reattemptCount < 10 ){
            timer = setTimeout(storeSubmission, 10000);
        }
        return () => {
            if( timer ){
                clearTimeout(timer)
            }
        }
    },[reattemptCount])

    return (
        <div className='results-page'>
            <h1 className="page-heading">What is your CCIM Personality?</h1>
            <h2>Your results are in!</h2>
            {!submitted &&
                <form onSubmit={submit}>
                    <p>Please enter your email to discover your personality!</p>
                    <div className="field">
                        <label htmlFor="first">First Name (optional)
                        <input id="first" placeholder="Charlie" type="name"
                               onChange={e => setFirstName(e.target.value)}/>
                        </label>
                        <label htmlFor="last">Last Name (optional)
                        <input id="last" placeholder="Cardinal" type="name"
                               onChange={e => setLastName(e.target.value)}/>
                        </label>
                    </div>
                    <div className={"field"}>
                        <label htmlFor="email">Email<span>*</span>
                        <input aria-label="Email - Required" id="email" placeholder="ccardinal@gmail.com" type="email" required
                               onChange={e => setEmail(e.target.value)}/>
                        </label>
                    </div>
                    <button className={'button'} disabled={email.length < 1}>Submit</button>
                    <small>By providing your email, Ball State may contact you with additional information about CCIM.</small>
                </form>
            }
            {submitted &&
                <div className="results">
                    <p className="submitted">Your email has been submitted.</p>
                    <div className="icon-results">
                        <img src={iconScribe} alt=""/>
                        {personalities!.length > 1 && <h2><output><b>You are hard to nail down!</b></output></h2>}
                    </div>
                    {personalities.map((personality,index)=>{
                        // @ts-ignore
                        const info = results[personality]
                        const headshot = findHeadshot(personality)
                        const icon = findIcon(personality)
                        if (personalities.length > 1 && index !== personalities.length-1) {
                            return( <div key={personality}><ResultBox professor={info.name} href={info.about_link} blurb={info.blurb}
                                              result_string={info.result_string} personality={personality}
                                              professorImage={headshot} image={icon} pronoun={info.pronoun} />
                                <h1><i>…and</i></h1>
                                </div>
                            )
                        }
                        else{
                            return <ResultBox key={personality} professor={info.name} href={info.about_link} blurb={info.blurb}
                                                    result_string={info.result_string} personality={personality}
                                                    professorImage={headshot} image={icon} pronoun={info.pronoun}/>
                        }
                    })}
                    <footer className="center">
                        <p className="outro">See how your personality fits with a CCIM major</p>
                        <a onClick={()=>{
                            ReactGA.outboundLink(
                                {
                                    label: 'Explore Your Options'
                                }, () => {}
                            );
                        }} href="https://www.bsu.edu/academics/collegesanddepartments/ccim/academic-programs" className="button large" target="_blank">Explore Your Options</a>
                    </footer>
                </div>
            }
            {!submitted && <video className={'placeholder-video'} src={placeholder} controls={false} muted={true} autoPlay={true} loop={true} playsInline={true} />}
        </div>
    )
}