import React, {useEffect} from 'react';
import './App.scss';
import {BrowserRouter as Router, matchPath, Route, Routes, useLocation} from "react-router-dom";
import {StartPage} from "./pages/StartPage";
import {QuizPage} from "./pages/QuizPage"
import {ResultsPage} from "./pages/ResultsPage";
import {BSUHeader} from "./components/BSUHeader/BSUHeader";
import {BSUFooter} from "./components/BSUFooter/BSUFooter";
import ReactGA from 'react-ga';

const App = () => {

    useEffect(()=>{
        if( window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1" ){
            ReactGA.initialize('UA-249574577-1');
        }

        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <>
            <BSUHeader />
                <Router>
                    <main>
                        <div className="App">
                            <Routes>
                                <Route path="/" element={<StartPage/>}/>
                                <Route path="/quiz" element={<QuizPage/>}/>
                                <Route path="/results/:personality" element={<ResultsPage/>}/>
                            </Routes>
                        </div>
                    </main>
                </Router>
            <BSUFooter />
        </>
    );
}

export default App;