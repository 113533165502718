import questions from "../questions.json";
import {FormEvent, useEffect, useState} from "react";
import {QuestionBox} from "../components/QuestionBox/QuestionBox";

import './QuizPage.scss';
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga";


export const QuizPage = () => {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    const navigate = useNavigate()

    const questionList = questions

    const [selections, setSelections] = useState([[""], [""], [""], [""], [""], [""]])

    const [incomplete, setIncomplete] = useState(false)

    const submitQuiz = (e:FormEvent) => {
        e.preventDefault();
        let totals = {
            "scribe": 0,
            "crafter": 0,
            "observer": 0,
            "visionary": 0,
            "strategizer": 0,
            "harmonizer": 0,
            "architect": 0,
            "bard": 0
        }
        selections.forEach((points) => {
            points.forEach((point) => {
                switch (point) {
                    case "scribe":
                        totals.scribe = totals.scribe + 1
                        break
                    case "crafter":
                        totals.crafter = totals.crafter + 1
                        break
                    case "observer":
                        totals.observer = totals.observer + 1
                        break
                    case "visionary":
                        totals.visionary = totals.visionary + 1
                        break
                    case "strategizer":
                        totals.strategizer = totals.strategizer + 1
                        break
                    case "harmonizer":
                        totals.harmonizer = totals.harmonizer + 1
                        break
                    case "architect":
                        totals.architect = totals.architect + 1
                        break;
                    case "bard":
                        totals.bard = totals.bard + 1
                        break;
                }
            })
        })

        const maxValue = Math.max(...Object.values(totals))

        let personalities: string[] = [];
        for (const [key, value] of Object.entries(totals)) {
            if (value === maxValue) {
                personalities.push(key);
            }
        }

        if (Object.values(selections)[0].includes('')) setIncomplete(true)
        else setIncomplete(false)

        navigate(`/results/${personalities.join("-")}`)
    }

    const handleAnswerSelect = (index: number, points: string[]) => {
        const tempSelections = selections
        tempSelections[index] = points
        setSelections(tempSelections)
    }

    return (
        <form className='question-page' onSubmit={(e) => {
            e.preventDefault()
            submitQuiz(e);
        }}>
            <h1 className="page-heading">What is your CCIM Personality?</h1>
            <div className={"content-container"}>
                {questionList.map((question, index) => {
                    return (
                        <QuestionBox key={question.question} choices={question.choices} questionIndex={index} questionsCount={questionList.length}
                                     question={question.question} index={index} setSelections={handleAnswerSelect}/>
                    )
                })}
            </div>
            <footer className="center">
                {incomplete && <p className="error">You haven't answered all of the questions!</p>}
                <button className="button large">See Your Results</button>
            </footer>
        </form>
    )
}
