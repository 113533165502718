import {useNavigate} from "react-router-dom";
import graphicHeading from "../assets/images/graphic-heading.svg";

import './StartPage.scss';

export const StartPage = () =>{

    const navigate = useNavigate()

    return (
        <div className='start-page'>
            <div className="center">
                <h1>
                    <img src={graphicHeading} alt="What is your CCIM Personality?"/>
                </h1>
                <p>Answer these questions to determine your CCIM persona!</p>
                <button className="button large" onClick={()=>navigate("/quiz")}>Let's Get Started</button>
            </div>
        </div>
    )
}