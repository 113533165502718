/* eslint-disable */
import './BSUHeader.scss';

type HeaderProps = {
	"logoLink"?:string,
	"logoLinkOpensInNewTab"?:boolean
}

export const BSUHeader = (props:HeaderProps) => {

	const logoLink = props.logoLink ?? 'https://bsu.edu';

	return (
		<div className="bsu-header">
			<form action="https://bsu.edu/search">

				<div id="offcanvasOverlay"></div>

				<div id="topmenu" role="navigation">
					<div className="container">
						<ul className="topnavleft">
							<li><a title="Admissions Information for Prospective Students" href="https://bsu.edu/admissions">Prospective Students</a></li>
							<li><a title="Resources and Services for Current Students" href="https://bsu.edu/gateways/current-students">Current Students</a></li>
							<li><a title="Resources and Services for Faculty and Staff" href="https://bsu.edu/gateways/faculty-and-staff">Faculty / Staff</a></li>
							<li><a title="Services and Events for Alumni" href="https://bsu.edu/about/administrativeoffices/alumni">Alumni</a></li>
							<li><a title="Tools and Information for Parents and Families" href="https://bsu.edu/gateways/parents">Parents</a></li>
						</ul>
						<ul className="topnavright">
							<li><a title="Search for Employees, Students, Departments, and More" href="https://bsu.edu/directory">Directory</a></li>
							<li><a title="Contact Us" href="https://bsu.edu/about/contactus">Contact Us</a></li>
							<li><a className="tacticalbtn" title="How to Apply to Ball State University" href="https://bsu.edu/admissions/apply">Apply</a></li>
							<li><a className="tacticalbtn" title="Learn How You Can Give to Ball State University" href="https://bsu.edu/foundation/give">Make a Gift</a></li>
						</ul>
					</div>
				</div>

				<div id="navUtility" className="navbar navbar-inverse" role="navigation" style={{zIndex: 'auto', position: 'relative', top: '0px'}}>
					<div className="container">
						<div className="navbar-header">
							<a href={logoLink} target={props.logoLinkOpensInNewTab ? '_blank' : '_self'} className="navbar-brand-inverse"><img src="https://bsu.edu/-/media/www/images/logos/bsu-logo_top.png" className="center-block" alt="Ball State University" width="402" height="112"/></a>

							<div className="navbar-form hidden-xs">
								<div className="input-group input-group-sm">
									<input name="q" id="ctl09_txtSearchVisible" className="form-control" title="Search bsu.edu" aria-label="enter search term" type="text" placeholder="Search bsu.edu" />
									<span className="input-group-btn">
										<button id="ctl09_btnSearchVisible" type="submit" aria-label="search go" className="btn btn-default">
											<svg className={'bsu-search-icon'} aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"/></svg>
										</button>
									</span>
								</div>
							</div>
						</div>
					</div>

					<div id="main-menu" className="navbar-offcanvas navmenu-fixed-right offcanvas navbar-subpage">
						<div>

							<ul className="nav navbar-nav">
								<li><a title="Academics" href="/academics">Academics</a></li>
								<li><a title="Admissions" href="/admissions">Admissions</a></li>
								<li><a title="Campus Life" href="/campuslife">Campus Life</a></li>
								<li><a title="About" href="/about">About</a></li>
								<li><a title="Give" href="/foundation/give">Give</a></li>
								<li><a title="Calendar" href="/calendar">Calendar</a></li>
								<li><a title="News" href="/news">News</a></li>
								<li><a href="https://www.ballstatesports.com" rel="noopener noreferrer" title="Athletics" target="_blank">Athletics</a></li>
							</ul>
						</div>
						<div>
							<div className="utilnavwrapper">
								<h5>Info For</h5>
								<ul className="nav navbar-nav small">
									<li><a title="Admissions Information for Prospective Students" href="/admissions">Prospective Students</a></li>
									<li><a title="Resources and Services for Current Students" href="/gateways/current-students">Current Students</a></li>
									<li><a title="Resources and Services for Faculty and Staff" href="/gateways/faculty-and-staff">Faculty / Staff</a></li>
									<li><a title="Services and Events for Alumni" href="/about/administrativeoffices/alumni">Alumni</a></li>
									<li><a title="Tools and Information for Parents and Families" href="/gateways/parents">Parents</a></li>
									<li className="divider"></li>
									<li><a title="Search for Employees, Students, Departments, and More" href="/directory">Directory</a></li>
									<li><a title="Contact Us" href="/about/contactus">Contact Us</a></li>
									<li><a className="tacticalbtn" title="How to Apply to Ball State University" href="/admissions/apply">Apply</a></li>
									<li><a className="tacticalbtn" title="Learn How You Can Give to Ball State University" href="/foundation/give">Make a Gift</a></li>
								</ul>
							</div>
							<ul className="nav navbar-nav social rowone">
								<li><a className="fab fa-facebook-f" target="_blank" title="facebook" aria-label="facebook" href="https://www.facebook.com/ballstate/"></a></li>
								<li><a className="fab fa-twitter" target="_blank" title="Twitter" aria-label="Twitter" href="https://twitter.com/ballstate"></a></li>
								<li><a className="fab fa-instagram" target="_blank" title="Follow Ball State on Instagram" aria-label="Follow Ball State on Instagram" href="https://www.instagram.com/ballstateuniversity/"></a>
								</li><li><a className="fab fa-youtube" target="_blank" title="Subscribe to Ball State's Official YouTube Channel" aria-label="Subscribe to Ball State's Official YouTube Channel" href="https://www.youtube.com/officialballstate"></a></li>
							</ul>
							<ul className="nav navbar-nav social rowsub">
								<li><a className="fab fa-linkedin-in" target="_blank" title="Follow Ball State on LinkedIn" aria-label="Follow Ball State on LinkedIn" href="https://www.linkedin.com/school/ball-state-university/"></a></li>
								<li><a className="fab fa-snapchat" target="" title="Follow Ball State on Snapchat" aria-label="Follow Ball State on Snapchat" href="https://www.snapchat.com/add/ourballstate"></a></li>
							</ul>
						</div>
					</div>

					<div id="utility-search" className="navbar-search offcanvas offcanvas-search navbar-fixed-top">
						<div className="container">
							<div className="row">
								<div className="col-xs-12">
									<div className="navbar-form">
										<div className="input-group input-group-sm">
											<input name="ctl09$txtSearchHidden" id="ctl09_txtSearchHidden" className="form-control" title="Search bsu.edu" aria-label="search term" type="text" placeholder="Search bsu.edu" />
											<span className="input-group-btn">
												<button id="ctl09_btnSearchHidden" type="submit" aria-label="open search function" className="btn btn-default">
													<svg className={'bsu-search-icon'} aria-hidden={true} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"/></svg>
												</button>
												<span className="sr-only">Search Button</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</form>
		</div>
	);
}