/* eslint-disable */
import './BSUFooter.scss';

type FooterProps = {
	"hideLogo"?: boolean
};

export const BSUFooter = (props:FooterProps) => {

	const year = new Date().getFullYear();

	return (
		<div className="bsu-footer">
			<div id="footer">
				<div className="container">
					<div className="row">
						{!props.hideLogo && <div className='footerlogo' style={{'backgroundImage': 'url(https://bsu.edu/-/media/www/images/sectionbackgrounds/home/plus_right_left.jpg?sc_lang=en)'}}>
							<a href='https://bsu.edu' title='Ball State University'>
								<img alt='Ball State University' src='https://bsu.edu/-/media/www/images/home/bsu_weflyvert_rgb_footer.png?sc_lang=en'/>
							</a>
						</div>}
						<div className="footerbottom">
							<div className="contactline">Ball State University 2000 W. University Ave. Muncie, IN 47306
								800-382-8540 and 765-289-1241
							</div>
							<div className="lastline">
								<ul className="list-inline">
									<li><strong>Copyright &copy; {year} Ball State University</strong></li>
									<li>
										<a href="https://bsu.edu/about/administrativeoffices/general-counsel/policies-and-legal-information/equal-opportunity-and-affirmative-action-policy">Equal Opportunity and Affirmative Action Policy</a>
									</li>
									<li>
										<a href="https://bsu.edu/about/administrativeoffices/general-counsel">Legal Information</a>
									</li>
									<li>
										<a href="https://bsu.edu/about/administrativeoffices/disability-services/about/contact">TTY Numbers</a>
									</li>
									<li>
										<a href="https://bsu.edu/sitemap">Sitemap</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}