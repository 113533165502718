import './ResultBox.scss';
import ReactGA from "react-ga";

type QuestionInfo = {
    "professor": string,
    "href": string,
    "blurb": string,
    "result_string": string,
    "personality": string,
    "professorImage": string,
    "image": string,
    "pronoun": string
}

export const ResultBox = (props: QuestionInfo) => {

    const article = ["a", "e", "i", "o", "u"].includes(props.personality.charAt(0)) ? "an" : "a"

    return (
        <>
            <h2><output>You are {article} <em className={"personality"}>{props.personality}</em></output></h2>
            <div className="icon-block">
                <img src={props.image} alt=""/>
                <p> {props.result_string} You might enjoy taking a class with <b>{props.professor}!</b>
                </p>
            </div>
            <div className="bio-box">
                <img src={props.professorImage} alt=""/>
                <div>
                    <h3>{props.professor}</h3>
                    <p>{props.blurb}</p>
                    <p>Want to learn more about {props.professor}? Check out <a onClick={()=>{
                        ReactGA.outboundLink(
                            {
                                label: props.professor+'’s Profile Page'
                            }, () => {}
                        );
                    }} href={props.href} target="_blank">{props.pronoun} faculty profile</a>!</p>
                </div>
            </div>
        </>
    )
}
