const results = {
	scribe: {
		result_string: "You are an interpreter and a writer. You take the information given to you and turn it into a written story that others love to read.",
		name: "Kate Elliott",
		email: "kelliott@bsu.edu",
		pronoun: "her",
		style: "Journalism Writing",
		blurb: "Kate Elliott guides the next generation of journalists and communication strategists to tell stories that inspire empathy, understanding and action. She also changes students’ tires and bakes them cookies because, sometimes, we all need a little help and carbs. Kate loves walking in nature, games, and exploring.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/sjsc/about-us/faculty-staff/magazine-media-concentration-faculty/kate-elliott"
	},
	crafter: {
		result_string: "You tell stories through thoughtful visuals. You can craft an engaging piece of art like it’s second nature.",
		name: "Brian Hayes",
		email: "bhayes@bsu.edu",
		pronoun: "his",
		style: "Journalism Graphics",
		blurb: "Brian Hayes is a passionate professor of journalism and graphic design, with a long background in journalism education. In his pastime, he enjoys barbecuing and tailgating at BSU football games. He thinks that the 80s and 90s were the best decades for country, rock, and rap music.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/sjsc/about-us/faculty-staff/media-design-and-development-faculty/hayesbrian"
	},
	observer: {
		result_string: "You can find a story in any moment. What others consider smaller details; you consider key pieces in a story.",
		name: "Chris Flook",
		email: "caflook@bsu.edu",
		pronoun: "his",
		style: "Media",
		blurb: "Chris Flook is a passionate Media professor and embraces immersive learning opportunities. He is an avid gardener, using his basement as a startup garden in the early springtime. When he gets to travel, he enjoys exploring southern Indiana and Hoosier National Forest.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/media/about-us/faculty-staff/flookchristopher"
	},
	visionary: {
		result_string: "You are a big-picture thinker. You can see what the final message should be and how it all fits together.",
		name: "Tim Underhill",
		email: "tunderhill@bsu.edu",
		pronoun: "his",
		style: "Media",
		blurb: "Tim Underhill has been carrying a camera since early childhood, capturing meaningful moments and stories around every corner. He enjoys exploring Indiana backroads on his bike and indulging in Chicago-style pizza.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/media/about-us/faculty-staff/underhilltim"
	},
	strategizer: {
		result_string: "You are thoughtful, analytical, and intentional. You could be a motivating leader or an inspirational colleague.",
		name: "Johnny Sparks",
		email: "jvsparks@bsu.edu",
		pronoun: "his",
		style: "PR/Advertising",
		blurb: "Dr. Sparks has a passion for research and strategy, connecting communication with psychology to understand how audiences are influenced by messages. He enjoys exploring Europe and climbing mountains, and his guilty pleasures are BBQ food and college sporting events.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/sjsc/about-us/faculty-staff/faculty/sparksjohnny"
	},
	harmonizer: {
		result_string: "You are resourceful and disciplined. You can look at a project from multiple angles and discover every possibility to recreate it.",
		name: "Alex Lukens",
		email: "aplukens@bsu.edu",
		pronoun: "his",
		style: "EMDD",
		blurb: "Alex Lukens studies the impact of strategic communication at the intersection of film, media, and digital platforms. His not-so guilty pleasures are watching bad movies and buying thrift store art. When he travels, he likes to go camping to get away from the hustle and bustle of life.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/sjsc/about-us/faculty-staff/emdd-faculty/lukensalexander"
	},
	bard: {
		result_string: "You are personable and persuasive. You enjoy building relationships with peers and are strategic with your words.",
		name: "Laura O’Hara",
		email: "lohara@bsu.edu",
		pronoun: "her",
		style: "Comm Studies",
		blurb: "Laura O’Hara loves teaching communication studies through immersive, interdisciplinary activities and courses. She is a lover of the arts and enjoys watching productions at Muncie Civic Theater and at BSU. Her guilty pleasures are Dairy Queen Blizzards and watching Downtown Abbey.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/communicationstudies/about-us/facultyandstaff/oharalaura"
	},
	architect: {
		result_string: "You are mechanistic and adaptable. You enjoy facing challenges and using every skill in your tool belt to get the job done.",
		name: "Kyle Church",
		email: "kdchurch@bsu.edu ",
		pronoun: "his",
		style: "CIT",
		blurb: "Kyle Church is an assistant lecturer in the computer and information technology department. He loves getting hands-on in every project, from technology to engines to woodworking. In his free time, he watches historical documentaries, anime, and reality TV shows.",
		about_link: "https://www.bsu.edu/academics/collegesanddepartments/cics/about-us/facultyandstaff/computer-and-information-technology/churchkyle"
	}
}

export default results;